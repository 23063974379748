<template>
  <ul class="nav nav-tabs" id="claimTab" role="tablist">
    <li v-for="tab in tabs" :key="'tab' + tab.id" class="nav-item" role="presentation">
        <button class="nav-link" :class="{'active': tab.showing}" :id="tab.name + '-tab'" type="button" role="tab" :aria-selected="tab.showing" @click="selectTab(tab)">{{tab.name}} <span v-if="tab.name !== 'Search'" class="tab-close-icon material-icons-outlined" @click.prevent="closeTab(tab)">close</span></button>
    </li>
  </ul>
  <div class="tab-content" id="claimTabContent">
      <div class="tab-pane fade" :class="{'show active': currentTab.name === 'Search'}" id="search" role="tabpanel" aria-labelledby="search-tab">
        <search @claim="selectClaim"></search>
      </div>
      <div v-for="tab in excludeSearch" :key="'tab' + tab.id + '-content'" class="tab-pane fade" :class="{'show active': tab.id === currentTab.id}" :id="tab.name + '-content'" role="tabpanel" :aria-labelledby="tab.name + '-tab'">
        <claim :claimReference="tab" :client_id="$store.state.auth.user.selectedClient"></claim>
      </div>
  </div>
</template>

<script>
import Claim from './claim/Claim.vue';
import Search from './Search.vue';
export default {
  components: { Search, Claim },
  name: 'Claims',
  data(){
    return {
      tabs: [
        {
          name: 'Search',
          id: -1,
          claim: false,
          showing: true
        }
      ]
    }
  },
  methods: {
    selectTab(tab){
      this.tabs.forEach(t => {
        t.showing = false;
      });

      tab.showing = true;
    },
    closeTab(t){
      if(t.showing){
          this.tabs.forEach(tab => {
              tab.showing = tab.name === 'Search';
          });
      }

      this.tabs = this.tabs.filter(tab => {
          return tab.id !== t.id;
      });

      // this.searchClaims(this.pagination.currPage);
    },
    selectClaim(claim){
      let existingCheck = this.tabs.filter(t => {
          return t.id == claim.id;
      })[0];
      if(existingCheck){
          this.tabs.forEach(t => {
              t.showing = t.id === claim.id;
          });
          return;
      }
      this.tabs.forEach(t => {
          t.showing = false;
      });
      this.tabs.push({
          name: claim.reference,
          id: claim.id,
          claim: true,
          showing: true
      });
    }
  },
  computed: {
    currentTab: function(){
      return this.tabs.filter((t) => {
        return t.showing;
      })[0];
    },
    excludeSearch: function(){
      return this.tabs.filter((t) => {
        return t.name !== 'Search';
      });
    }
  }
}
</script>

<style scoped>
.tab-close-icon {
  font-size: 15px;
  vertical-align: middle;
  margin-bottom: 4px;
}
.tab-close-icon:hover {
  color: red;
}
</style>